import React from 'react';
import { Container, Grid, Typography } from "@material-ui/core";

import "./apps-section.scss";
import { AppCard } from './AppCard/AppCard';
import { APPS_INFO } from '../../static';
import { useTranslation } from "react-i18next";

export const AppsSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container fixed component="section" className="apps" id="apps">
     <Typography
        component="h3"
        variant="h4"
        color="primary"
        className="apps__title"
      >
        {t("apps_section_title")}
      </Typography>
      <Grid container spacing={4}>
        {APPS_INFO.map(app => (
          <AppCard 
            key={app.id} 
            title={app.title} 
            description={app.description} 
            icon={app.icon}
            app_store_link={app.app_store_link}
            google_play_link={app.google_play_link}
          />
        ))}
      </Grid>
    </Container>
  )
}