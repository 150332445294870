import React, {useEffect, useState} from "react";
import {Box, Container, Grid, Typography} from "@material-ui/core";
import { useTranslation } from "react-i18next";


import { massagers } from "../../static";
import "./massagers-section.scss";
import { SeparateMassager } from "../SeparateMassager/SeparateMassager";

import Slider from "react-slick";

interface IProps {
  mainPage?: boolean;
}

export const MassagersSection: React.FC<IProps> = ({ mainPage = false }) => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 600);

  const { t } = useTranslation();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: isMobile ? 1 : 4,
    slidesToScroll: 1,
    variableWidth: !isMobile,
    waitForAnimate: false,
    draggable: !isMobile,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          variableWidth: true,
        },
      },
      {
        breakpoint: 960,
        settings: {
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          variableWidth: false,
          dots: true,
          arrows: false,
          adaptiveHeight: true,
        },
      },
    ],
  };

  return !mainPage ? (
    <Container fixed component="section" className="massagers" id="massagers">
      <Typography
        component="h3"
        variant="h4"
        color="primary"
        className="massagers__title"
      >
        {t("massagers_section_title")}
      </Typography>
      <Typography
        component="p"
        variant="h6"
        color="textPrimary"
        className="massagers__description"
        dangerouslySetInnerHTML={{ __html: t("massagers_section_description") }}
      />
      <Grid container direction="row" spacing={5}>
       {massagers.map(({ id, ...restProps }) => (
         <SeparateMassager
           id={id}
           {...restProps}
         />
       ))}
      </Grid>
    </Container>
  ) : (
      <Container component="section" className="massage" id="massage" fixed>
      <Box
        display="flex"
        flexDirection="column"
        className="massage__wrapper"
        data-aos="zoom-in-down"
      >
        <Typography
          component="h3"
          variant="h4"
          color="primary"
          className="massagers__title"
        >
          {t("massagers_section_title")}
        </Typography>
        <Slider {...settings} className="massagers__slider">
            {massagers.map(({ id, ...restProps }) => (
              <SeparateMassager
                id={id}
                key={id}
                mainPage={mainPage}
                {...restProps}
              />
            ))}
        </Slider>
      </Box>
    </Container>
  );
};
