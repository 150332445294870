import React from "react";

import {
  Grid,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import "./separate-massager.scss";
import {Link} from "react-router-dom";

interface IProps {
  id: number;
  name: string;
  title: string;
  material: string;
  actualPrice: number;
  mainPage?: boolean;
}

export const SeparateMassager: React.FC<IProps> = ({
  id,
  name,
  title,
  material,
  actualPrice,
  mainPage
}) => {
  const { t } = useTranslation();

  const imageSource = (() => {
    switch (id) {
      case 1:
        return "/massagers/classic/wood";
      case 2:
        return "/massagers/classic/plastic";
      case 3:
        return "/massagers/mount/wood";
      case 4:
        return "/massagers/mount/plastic";
      case 5:
        return "/massagers/wave/wood";
      case 6:
        return "/massagers/wave/plastic";
    }
  })();

  return (
    <Grid item className="separate-massager" {...(mainPage ? {} : { xs: 12, sm: 12, md: 4 })}>
      <Link to={"/massagers/" + name} className="separate-massager__img">
        <img
          srcSet={`
            ${imageSource}-xs.webp 300w,
            ${imageSource}-s.webp 500w,
            ${imageSource}-m.webp 700w,
            ${imageSource}-l.webp 880w,
            ${imageSource}-xl.webp 1024w
          `}
          src={`/massagers/${imageSource}-xl.webp`}
          alt={t(title) as string}
        />
      </Link>
      <Grid item className="separate-massager__title-material" md={12}>
        <Typography className="separate-massager__title" component="h4" color="primary">
          {t(title)}
        </Typography>
        <Typography className="separate-massager__material" component="div" color="textPrimary">
          ({t(material).toLowerCase()})
        </Typography>
      </Grid>
      <Typography className="separate-massager__price" component="p">
        {actualPrice} грн
      </Typography>
    </Grid>
  );
};
