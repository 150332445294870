import React from "react";
import { Grid, Link, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";


import "./app-card.scss";
import AppStore from "../../../assets/images/AppStore.webp";
import GooglePlay from "../../../assets/images/GooglePlay.webp";

interface AppCardProps {
  title: string;
  icon: string;
  description: string;
  app_store_link: string;
  google_play_link: string;
}

export const AppCard: React.FC<AppCardProps> = ({
  title,
  icon,
  description,
  app_store_link,
  google_play_link
}) => {
  const { t } = useTranslation();

  return (
     <Grid item container xs={12} md={6} className="app">
       <Grid item container>
        <Grid item xs={12} md={4}>
         <Grid item container xs={12} md={12}>
           <Grid item xs={2} md={12} className="app__img">
            <img src={icon} alt="BodyMaxRehab"/>
           </Grid>
           <Grid item xs={3} md={12}>
             <Link href={app_store_link} className="app__download">
               <img src={AppStore} alt="AppStore"/>
             </Link>
             <Link href={google_play_link} className="app__download">
               <img src={GooglePlay} alt="GooglePlay"/>
             </Link>
           </Grid>
         </Grid>
        </Grid>
        <Grid item md={8} xs={12}>
         <Typography
          variant="body1"
          color="textPrimary"
          className="app_description"
          dangerouslySetInnerHTML={{__html: t(description)}}
         />
        </Grid>
       </Grid>
     </Grid>
  );
};
