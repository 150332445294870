// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.apps__title.MuiTypography-root {
  margin-bottom: 20px;
}
@media screen and (max-width: 1279px) {
  .apps__title.MuiTypography-root {
    font-size: 1.9rem;
  }
}
@media screen and (max-width: 959px) {
  .apps__title.MuiTypography-root {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 599px) {
  .apps__title.MuiTypography-root {
    font-size: 1.1rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/AppsSection/apps-section.scss","webpack://./src/assets/sass/media.scss"],"names":[],"mappings":"AAIE;EACE,mBAAA;AAHJ;ACDE;EDGA;IAII,iBAAA;EAFJ;AACF;ACAE;EDHA;IAQI,iBAAA;EADJ;AACF;ACCE;EDTA;IAYI,iBAAA;EAAJ;AACF","sourcesContent":["@import \"../../assets/sass/media.scss\";\r\n\r\n.apps {\r\n\r\n  &__title.MuiTypography-root {\r\n    margin-bottom: 20px;\r\n\r\n    @include laptop {\r\n      font-size: 1.9rem;\r\n    }\r\n\r\n    @include tablet {\r\n      font-size: 1.6rem;\r\n    }\r\n\r\n    @include mobile {\r\n      font-size: 1.1rem;\r\n    }\r\n  }\r\n}","@mixin laptop {\r\n  @media screen and (max-width: 1279px) {\r\n    @content;\r\n  }\r\n}\r\n\r\n@mixin tablet {\r\n  @media screen and (max-width: 959px) {\r\n    @content;\r\n  }\r\n}\r\n\r\n@mixin mobile {\r\n  @media screen and (max-width: 599px) {\r\n    @content;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
